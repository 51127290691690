@font-face {
  font-family: 'Old London';
  src: url('./assets/fonts/OldLondon.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.container {
  position: relative;
  background: url('./assets/sfondo.png') no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  min-width: 100%;
}

.overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  text-align: center;
  background-color: #a41c14;
  opacity: 90%;
  width: 90%;
  max-width: 1000px;
  margin: auto;
  margin-top: 2rem;
  padding: 1rem;
}

.gif-logo {
  position: relative;
  margin-bottom: 1rem;
  width: 4rem;
  height: auto;
}

.title-container {
  border: 5px solid black;
  padding: 0.5rem 1rem;
  text-align: center;
  margin-top: 0;
  margin-bottom: 1rem;
}

.title1,
.title2 {
  font-size: 4rem;
  font-weight: 550;
  font-family: 'Old London', sans-serif;
}

.subtitle {
  font-size: 1.15rem;
  font-family: 'Libre Baskerville', serif;
}

.event-title {
  font-size: 2rem;
  font-weight: 1000;
  font-family: 'Libre Baskerville', serif;
}

.event-details {
  margin-top: 1rem;
  font-size: 1rem;
  text-align: left;
  display: inline-block;
}

.event-promo {
  text-align: center;
  margin: 1rem;
  font-size: 1rem;
}

.footer {
  text-align: center;
  margin: 1rem;
  font-size: 0.6rem;
}

.map-container {
  width: 100%;
  max-width: 600px;
  height: 300px;
  margin-top: 1rem;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}

/* Add a media query for larger screens */
@media (min-width: 1100px) {

  .gif-logo {
    position:relative;
    top: 1rem;
    left: -20rem;
    width: 7rem;
  }

  .title-container {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .title1, .title2 {
    font-size: 7rem;
  }

  .subtitle {
    font-size: 1.25rem;
  }

  .event-title {
    font-size: 3rem;
  }

  .event-details {
    font-size: 1.25rem;
  }

  .event-promo {
    margin: 1rem 10rem;
    font-size: 1.15rem;
  }

  .footer {
    margin: 1rem 10rem;
    font-size: 0.8rem;
  }

  .map-container {
    height: 500px;
  }
}
